// Modules
import React, { useEffect, useState } from "react";
import FormValidator from "../../../validation/formValidator";
import { bookingValidator } from "../../../validation/bookingValidator";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { css } from "emotion";

// Utilities
import req from "../../../utilities/request-utility";

// Styles
import colors from "../../../styles/colors";

// Actions
import { addToast, hideModal, showModal } from "../../../actions/uiActions";
import { temporarySaveBooking } from "../../../actions/storageActions";

// Components
import TopSection from "./TopSection";
import MiddleSection from "./MiddleSection";
import BottomSection from "./BottomSection";
import PageContainer from "../../ui/PageContainer";
import NavigateBackArrow from "../../ui/NavigateBackArrow";
import StepBar from "../../ui/StepBar";
import Spinner from "../../ui/Spinner";
import ConfirmBookingModal from "./ConfirmBookingModal";

// Styles
import { breakPoint } from "../../../styles/breakPoints";

const NewBooking = props => {
  const history = useHistory();

  // Redux stored booking
  const { booking: firstStepBooking } = useSelector(state => state.storage);

  // Validation form
  const validator = new FormValidator(bookingValidator);
  const { user } = useSelector(state => state.auth);

  const fieldValues = {
    id: user.id || null,
    corporation_id: 1,
    staff_type: "",
    number_of_staff: 1,
    date: "",
    start_time: "10:00",
    end_time: "18:00",
    booking_person: "",
    address: "",
    booking_person_nr: "",
    zip_code: "",
    city: "",
    arrangement_type: "Firma event",
    arrangement_type_other: "",
    upper_dress_code: "Hvid skjorte",
    upper_dress_code_other: "",
    lower_dress_code: "Sorte buks",
    lower_dress_code_other: "",
    shoes_dress_code: "Sorte sko",
    shoes_dress_code_other: "",
    item_to_bring: "",
    extra_work_hours: " ",
    contact_person: "",
    food_included: "",
    job_description: "",
    access_information: "Hovedindgangen",
    access_information_other: "",
    transport_wage: "",
    hourly_wage: "192",
    fee: 0,
    language: "",
    invoice_note: "",
    address_url: "",
    admin_message: "",
    is_email_script_allowed: true,
    validation: validator.valid()
  }

  const [booking, setBooking] = useState({...fieldValues});

  // Validation submitted ( Validation)
  const [submitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState(booking.validation);
  const [notSendEmail, setNotSendEmail] = useState(false);

  const [loading, setLoading] = useState(false);

  // Show the full create booking page button
  const [showFullBooking, setShowFullBooking] = useState(false);

  // create or save booking
  const createBooking =  async () => {
    setLoading(true)
    const validation = await validator.validate(booking);
    setLoading(false)
    const tempBooking = { ...booking };
    tempBooking.validation = validation;
    setSubmitted(true);
    if (validation.isValid) {
      if (user.id) {
        props.showModal({
          title: "DU ER VED AT AFGIVE EN BOOOKING",
          content: (
            <ConfirmBookingModal
              notSendEmail={notSendEmail}
              booking={booking}
              setLoading={value => {
                setLoading(value);
              }}
            />
          )
        });
      } else {
        props.temporarySaveBooking(booking);
        history.push("/newcorporation");
      }
    } else {
      // Force rerender component
      setBooking(tempBooking);
      // Show warning
      props.addToast({
        message: "Nogle felter er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true
      });
    }
  };

  const setPriceAndState = e => {
    let tempBooking = { ...booking };
    tempBooking[e.target.name] = e.target.value;

    setBooking(tempBooking);
  };

  // If signed in, show full booking page
  useEffect(() => {
    setBooking({...booking, payment_type: user.payment_type, payment_deadline: user.payment_deadline})
    if (user.id) {
      setShowFullBooking(true);
    } else if (firstStepBooking) {
      setBooking({ ...booking, ...firstStepBooking });
    }
    // eslint-disable-next-line
  }, []);

  // Function to round minutes to 00 or 30
  const roundMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    const roundedMinutes = Math.floor(parseInt(minutes) / 30) * 30;
    return `${hours}:${roundedMinutes === 0 ? '00' : '30'}`;
  };

  const getLastBooking = (staff_type, staff_type_billy_id) => {
    if (user.id) {
      setShowFullBooking(true);
      // Get the latest booking
      req()(`corporations/bookings/last-created-booking?staff_type=${staff_type}`).then(
        ({ data: latestBooking }) => {
          if (latestBooking) {
            let start_time = latestBooking.start_time;
            let end_time = latestBooking.end_time;
            start_time = roundMinutes(start_time);
            end_time = roundMinutes(end_time);
            setBooking({ ...booking, ...latestBooking, start_time, end_time });
          }else {
            setBooking({ ...booking, ...fieldValues,staff_type, staff_type_billy_id})
          }
        }
      );
    }
  }

  // Validation
  useEffect(() => {
    const updateValidation = async () => {
      if (submitted) {
        const result = await validator.validate(booking);
        setValidation(result);
      } else {
        setValidation(booking.validation);
      }
    };
  
    updateValidation();
    // eslint-disable-next-line
  }, [booking, submitted]);
  

  return (
    <PageContainer showNavigation={true} navigationBarType={"corporation"}>
      <div className={componentStyle(colors)}>
        {loading && (
          <div className="loading-container">
            <Spinner title={"Opretter booking..."} />
          </div>
        )}

        {!loading && (
          <>
            {user.id && (
              <>
                <NavigateBackArrow navigateURL={() => history.push(`/`)} />
              </>
            )}
            <div className="create-booking-container">
              <p className="title">Opret ny booking</p>
              <p className="sub-title">
                Udfyld formularen forneden og opret en uforpligtende
                forespørgsel
              </p>

              {!user.id && (
                <div className={"step-bar"}>
                  <StepBar
                    currentStep={1}
                    steps={2}
                    titles={["OPRET GRATIS BOOKING", "OPRET VIRKSOMHED"]}
                  />
                </div>
              )}

              <TopSection
                booking={booking}
                setBooking={setBooking}
                validation={validation}
                setPriceAndState={setPriceAndState}
                lastBooking={getLastBooking}
              />

              {showFullBooking ? (
                <>
                  <MiddleSection
                    booking={booking}
                    setBooking={setBooking}
                    validation={validation}
                    setPriceAndState={setPriceAndState}
                    setNotSendEmail={setNotSendEmail}
                    notSendEmail={notSendEmail}
                  />

                  <div className="bottom-btn-container">
                    {user.id ? (
                      <div>
                        <button onClick={() => createBooking()}>
                          Opret gratis booking
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button onClick={() => createBooking()}>Fortsæt</button>
                      </div>
                    )}
                    <BottomSection 
                      booking={booking}
                      setBooking={setBooking}
                    />
                    </div>
                </>
              ) : (
                <div className="bottom-btn-container">
                  <button
                    onClick={() => setShowFullBooking(true)}
                    style={{ width: "13.6rem", margin: "auto" }}
                  >
                    Videre
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </PageContainer>
  );
};

const componentStyle = colors => css`
  height: 100%;
  width: 100%;

  .step-bar {
    margin: 2rem 0;

    @media screen and (max-width: ${breakPoint.sm}px) {
      display: none;
    }
  }

  .create-booking-container {
    margin-top: 1rem;
    padding: 0 3rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0 1rem;
      margin-top: 0.5rem;
    }

    .title {
      font-size: 2.25rem;

      @media screen and (max-width: ${breakPoint.sm}px) {
        font-size: 1.5rem;
      }
    }

    .sub-title {
      margin-bottom: 2rem;
      color: ${colors.darkerGrey};

      @media screen and (max-width: ${breakPoint.sm}px) {
        margin: 0;
        font-size: 1rem;
      }
    }
  }

  .bottom-btn-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    button {
      background-color: ${colors.lightBlue};
      outline: none;
      color: ${colors.white};
      font-weight: 700;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      text-align: center;
    }

    button:hover {
      background-color: ${colors.blue};
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

`;

const mapDispatchToProps = dispatch => ({
  temporarySaveBooking: bindActionCreators(temporarySaveBooking, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
  hideModal: bindActionCreators(hideModal, dispatch),
  showModal: bindActionCreators(showModal, dispatch)
});

export default connect(null, mapDispatchToProps)(NewBooking);