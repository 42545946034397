// Modules
import React, { useEffect, useState, useRef, forwardRef } from "react";
import { css } from "emotion";
import moment from "moment";
import { useSelector, connect } from "react-redux";
import { bindActionCreators } from "redux";

// Styles
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import AccountTieIcon from "mdi-react/AccountTieIcon";
import MapMarkerIcon from "mdi-react/MapMarkerIcon";
import PhoneIcon from "mdi-react/PhoneIcon";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import "react-datepicker/dist/react-datepicker.css";

import colors from "../../../styles/colors";
import { myCustomLocale } from "../../../styles/custom-datepicker-language-locale";

// Components
import DatePicker from "react-modern-calendar-datepicker";
// import DatePicker from "react-datepicker";

import InputField from "../../ui/InputField";
import Label from "../../ui/Label";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";

// Config
import { staff, staffNumber, hours, minutes } from "./config";
import bookingTypes from "../../../config/bookingTypes";

// Utilities
import { calculateFee } from "../../../utilities/calculate-booking-fee";

// Styles
import { breakPoint } from "../../../styles/breakPoints";

// Actions
import { getStaffs } from "../../../actions/staffActions";

const TopSection = (props) => {
  const inputRef = useRef();

  const[plusCde,setPlusCode] = useState('')

  const { booking, setBooking, validation, edit } = props;

  useEffect(() => {
    props.getStaffs();
  }, []);
  
  // Redux state
  const { takenOverCorporation, user } = useSelector((state) => state.auth);
  const { staffs, loading, error, endOfFeed } = useSelector((state) => state.staff);
  // const [startDate, setStartDate] = useState(null);

  const [bookingTime, setBookingTime] = useState({
    startHours: "10",
    startMinutes: "00",
    endHours: "18",
    endMinutes: "00",
  });

  useEffect(() => {
    if (booking.start_time || booking.end_time) {
      setBookingTime({
        ...bookingTime,
        startHours: booking.start_time.split(":")[0],
        startMinutes: booking.start_time.split(":")[1],
        endHours: booking.end_time.split(":")[0],
        endMinutes: booking.end_time.split(":")[1],
      });
    }

    // eslint-disable-next-line
  }, [booking.start_time, booking.end_time]);

  // Datepicker style element
  const renderCustomInput = ({ ref }) => (
    <input
      disabled={edit}
      readOnly
      ref={ref} // necessary
      placeholder="Vælg en dato"
      value={booking.date ? `${moment(booking.date).format("DD-MM-YYYY")}` : ""}
      className={`date-input ${edit && "disabled"}`}
      style={{ cursor: "pointer" }}
    />
  );
  // const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  //   <input
  //     disabled={edit}
  //     readOnly
  //     ref={ref} // necessary
  //     placeholder="Vælg en dato"
  //     value={booking.date ? `${moment(booking.date).format("DD-MM-YYYY")}` : ""}
  //     className={`date-input ${edit && "disabled"}`}
  //     style={{ cursor: "pointer" }}
  //     onClick={onClick}
  //   />
  // ));

  // Address autocomplete
  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    let address = "";
    let plusCode = "";
    let city = "";
    let url = place.url;
    if (place.address_components) {
      
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];
    
        switch (componentType) {
          case "street_number": {
            address = `${component.long_name} ${address}`;
            break;
          }
    
          case "route": {
            address = `${component.short_name} ${address}`;
            break;
          }  
          case "postal_code": {
            plusCode = `${component.long_name}${plusCode}`;
            break;
          }
    
          case "postal_code_suffix": {
            plusCode = `${plusCode}-${component.long_name}`;
            break;
          }
          case "locality":
            city = component.long_name;
            break;
        }
      }
    }
  
    if (place) {
      setBooking({ ...booking, address, zip_code: plusCode, city, address_url: url })
    }
  };

  // Updates the time & the total price
  const bookingTimeHandler = async(e) => {
    if (e.target.name === "start_time") {
      e.target.name = "startHours";
    }

    // Change bookingTime
    const bt = { ...bookingTime };
    bt[e.target.name] = e.target.value;
    setBookingTime(bt);
    // Change booking
    let b = { ...booking };
    b = await calculateFee(b.date, b);
    b.start_time = `${bt.startHours}:${bt.startMinutes}`;
    b.end_time = `${bt.endHours}:${bt.endMinutes}`;
    // Update state
    setBooking(b);
  };
    const numberOfStaffSelectionEnabled =
((booking.hoursRemaining > 72 && booking.status !== bookingTypes.closed) || user.is_admin || takenOverCorporation);

    // const handleDateChange = async (date) => {
    //   const formattedDate = moment(date); // Convert to moment object
    //   setBooking(
    //     await calculateFee(formattedDate, { ...booking })
    //   );
    // };
  return (
    <div className={componentStyle(colors)}>
      {/* 1. Section */}
      <div className="section-one">
        <div className="outer-container">
          {/* Vælg personale */}
          <div className="staff-container">
            <Label value={"Type personale"} />
            <div style={{ position: "relative" }}>
              {edit !== undefined ? <InputField
              value={booking.staff_type}
              /> : 
              <>
                <select
                value={booking.staff_type}
                onChange={(e) => {
                  const staff_type_billy_id = staffs.find(staff => staff.job_title == e.target.value)?.billy_id;
                  setBooking({ ...booking, staff_type: e.target.value, staff_type_billy_id });
                    props.lastBooking(e.target.value, staff_type_billy_id)
                }}
                name="staff_type"
                className={edit && "disabled"}
                id="grid-state"
                style={
                  booking.staff_type === ""
                    ? {
                        color: `${colors.placeholder}`,
                      }
                    : {}
                }
                placeholder={"test"}
              >
                <option value={0}>{"Angiv"}</option>
                {staffs.map((staff, index) => (
                  <option key={index} value={staff.job_title} disabled={!staff.company}>
                    {staff.job_title} {!staff.company && '🚫' }
                  </option>
                ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black">
                  <ChevronDownIcon />
                </div>
              </>
              }
            </div>
            <p className="invalid-text">{validation.staff_type?.message}</p>
          </div>

          {/* Dato */}
          <div className="date-container">
            <div style={{ position: "relative" }}>
              <Label value={"Angiv dato"} />
              <DatePicker
                wrapperClassName={"datepicker"}
                renderInput={renderCustomInput} // render a custom input
                shouldHighlightWeekends
                locale={myCustomLocale}
                onChange={async(date) => {
                  setBooking(
                    await calculateFee(
                      moment()
                        .set("year", date.year)
                        .set("month", date.month - 1)
                        .set("date", date.day),
                      { ...booking }
                    )
                  );
                }}
              />
              {/* <DatePicker
                wrapperClassName={"date-input"}
                withPortal={true}
                className={`date-input`}
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                  handleDateChange(date);
                }}
                placeholder={'Vælg en dato'}
                customInput={<CustomInput />}  
                // customInput={<ExampleCustomInput className="example-custom-input" />}
                highlightDates={[{ "react-datepicker__day--highlighted-custom": new Date() }]} // Example to highlight weekends
                dateFormat="dd-MM-yyyy"
                locale="en" // Or any custom locale
              /> */}
              <p className="invalid-text">{validation.date?.message}</p>
            </div>
          </div>

          {/* Antal personale */}
          <div className="amount-staff-container">
            <Label value={"Antal"} />
            <div style={{ position: "relative" }}>
              <select
                disabled={edit && !numberOfStaffSelectionEnabled}
                value={booking.number_of_staff}
                name="number_of_staff"
                onChange={(e) =>
                  setBooking({
                    ...booking,
                    number_of_staff: e.target.value,
                  })
                }
              >
                {staffNumber.map((staff, index) => (
                  <option key={index} value={staff.value}>
                    {staff.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* TID */}
        <div className="outer-container" style={{ alignItems: "flex-end" }}>
          {/* Timer */}
          <div className="time-container">
            <Label value={"Fra"} />

            <div style={{ position: "relative" }}>
              <select
                value={bookingTime.startHours}
                name="start_time"
                onChange={(e) => bookingTimeHandler(e)}
              >
                {hours.map((hour, index) => (
                  <option key={index} value={hour.value}>
                    {hour.value}
                  </option>
                ))}
              </select>
              <p className="invalid-text">{validation.start_time?.message}</p>
            </div>
          </div>

          <div className="colon">
            <span>:</span>
          </div>

          {/* Minutter */}
          <div className="time-container">
            <div style={{ position: "relative" }}>
              <Label />

              <select
                value={bookingTime.startMinutes}
                name="startMinutes"
                onChange={(e) => bookingTimeHandler(e)}
              >
                {minutes.map((minutes, index) => (
                  <option key={index} value={minutes.value}>
                    {minutes.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Klokke Ikon */}
          <div className="colon">
            <ClockOutlineIcon />
          </div>

          {/* Timer */}
          <div className="time-container">
            <Label value={"Til"} />

            <div style={{ position: "relative" }}>
              <select
                value={bookingTime.endHours}
                name="endHours"
                onChange={(e) => bookingTimeHandler(e)}
              >
                {hours.map((hour, index) => (
                  <option key={index} value={hour.value}>
                    {hour.value}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="colon">
            <span>:</span>
          </div>

          {/* Minutter */}
          <div className="time-container">
            <div style={{ position: "relative" }}>
              <Label />

              <select
                value={bookingTime.endMinutes}
                name="endMinutes"
                onChange={(e) => bookingTimeHandler(e)}
              >
                {minutes.map((minutes, index) => (
                  <option key={index} value={minutes.value}>
                    {minutes.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="section-two">
        <div className="extra-div">
          <Label value={"Ansvarlig for booking"} />

          <div style={{ position: "relative" }}>
            <InputField
              value={booking.booking_person}
              name="booking_person"
              onChange={(e) =>
                setBooking({ ...booking, [e.target.name]: e.target.value })
              }
              placeholder="Fulde navn"
              style={{ paddingLeft: "2.5rem" }}
            />
            <div className="png-div-right">
              <AccountTieIcon />
            </div>
          </div>
          <p className="invalid-text">{validation.booking_person?.message}</p>
        </div>
        <div className="extra-div">
          <Label value={"Adresse for event"} />
          <div style={{ position: "relative" }}>

            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              libraries={["places"]}
            >
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <InputField name="address" type="text" placeholder="adresse" style={{ paddingLeft: "2.3rem" }} value={booking.address} onChange={(e) => {
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                      ['city']: '',
                      ['zip_code']: ''
                    });
                }}
/>
              </StandaloneSearchBox>
              <div className="png-div-right">
                <MapMarkerIcon />
              </div>
            </LoadScript>
          </div>
          <p className="invalid-text">{validation.address?.message || validation.address_url?.message}</p>
        </div>
      </div>

      <div className="section-three">
        <div className="extra-div">
          <div>
            <Label
              value={"Kontakt til ansvarlig. Modtager stempel-ud link!"}
            />

            <div style={{ position: "relative" }}>
              <InputField
                value={booking.booking_person_nr}
                name="booking_person_nr"
                onChange={(e) => {
                  /^[0-9]{0,8}$/.test(e.target.value) &&
                    setBooking({
                      ...booking,
                      [e.target.name]: e.target.value,
                    });
                }}
                placeholder="Mobil nr."
                style={{ paddingLeft: "2.5rem" }}
              />
              <div className="png-div-right">
                <PhoneIcon />
              </div>
            </div>
            <p className="invalid-text">
              {validation.booking_person_nr?.message}
            </p>
          </div>
        </div>

        <div
          className="extra-div"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div className="zip-container">
            <Label value={"Post nr."} />
            <InputField
              value={booking.zip_code}
              name="zip_code"
              readOnly={true}
              className={'disabled'}
              // onChange={(e) => {
              //   /^[0-9]{0,4}$/.test(e.target.value) &&
              //     setBooking({ ...booking, [e.target.name]: e.target.value });
              // }}
            />
            <p className="invalid-text">{validation.zip_code?.message}</p>
          </div>
          <div className="city-container">
            <Label value={"By"} />
            <InputField
              value={booking.city}
              name="city"
              readOnly={true}
              className={'disabled'}
              // onChange={(e) =>
              //   setBooking({ ...booking, [e.target.name]: e.target.value })
              // }
            />
            <p className="invalid-text">{validation.city?.message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
  .datepicker {
    z-index: 1 !important;
  }

  .header-title {
    font-weight: 700;
    font-size: 1.125rem;
  }
  .address-autocomplete {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
  }
  .invalid-text {
    text-align: center;
    color: ${colors.red};
    font-size: 0.6rem;
  }

  .date-input {
    cursor: text;
    display: block;
    font-size: 0.75rem;
    outline: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.6rem;
    }

    &.disabled {
      background-color: ${colors.grey};
      border: 1px solid ${colors.grey};
      cursor: no-drop;
    }
    &.disabled:hover {
      border: 1px solid ${colors.grey};
    }
  }
  .date-input:hover {
    border: 1px solid ${colors.black};
  }

  .date-input::placeholder {
    color: ${colors.placeholder};
  }

  hr {
    border-top-width: 1px;
    margin-bottom: 0.5rem;
  }

  select {
    cursor: pointer;
    display: block;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    outline: none;

    @media screen and (max-width: ${breakPoint.sm}px) {
      padding: 0.6rem 0.9rem;
    }
  }
  select:hover {
    border: 1px solid ${colors.black};
  }

  select::placeholder {
    background-color: ${colors.placeholder};
  }
  select:disabled {
    background-color: #eee;
  }
  .section-one {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin: 1.5rem 0;
    align-items: baseline;

    @media screen and (max-width: ${breakPoint.sm}px) {
      flex-direction: column;
      align-items: center;
    }

    .outer-container {
      display: flex;
      align-items: baseline;

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }

      &:last-of-type {
        justify-content: flex-end;

        @media screen and (max-width: ${breakPoint.sm}px) {
          width: 100%;
          justify-content: center;
        }
      }

      .colon {
        margin-top: 2rem;
        margin-right: 0.5rem;
      }

      .staff-container {
        width: 7.2rem;
        margin-right: 0.5rem;
      }

      .date-container {
        width: 7.5rem;
        margin-right: 0.5rem;
      }

      .amount-staff-container {
        width: 3.3rem;
      }

      .time-container {
        width: 3.1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .section-two,
  .section-three {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    @media screen and (max-width: ${breakPoint.sm}px) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .extra-div {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 50%;

      &:first-of-type {
        margin-right: 0.5rem;
        @media screen and (max-width: ${breakPoint.sm}px) {
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-left: 0.5rem;
        @media screen and (max-width: ${breakPoint.sm}px) {
          margin-left: 0;
        }
      }

      @media screen and (max-width: ${breakPoint.sm}px) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .zip-container {
      width: 4rem;
      margin-right: 0.5rem;
      input {
        background-color: ${colors.superLightGrey}    
      }
      input:hover {
        border: 1px solid #718096;    
      }
    }
    .city-container {
      flex: 1;
      input {
        background-color: ${colors.superLightGrey}
      }
      input:hover {
        border: 1px solid #718096;    
      }
    }
  }

  .png-div-left,
  .png-div-right {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: ${colors.black};
  }

  .png-div-right {
    left: 0;
    right: auto;
  }
`;

const mapDispatchToProps = (dispatch) => ({
  getStaffs: bindActionCreators(getStaffs, dispatch),
});

export default connect(null, mapDispatchToProps)(TopSection);
